<template>
  <div class="auth-wrapper auth-v1">
    <v-snackbar
      v-model="isSnackbarCenteredVisible"
      :light="$vuetify.theme.dark"
      centered
      :color="errorColor"
      timeout="3000"
    >
      {{snackbarMessage}}
    </v-snackbar>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold brand-name">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Забыли пароль? 🔒
          </p>
          <p class="mb-2">
            Введите адрес электронной почты, и мы вышлем вам инструкции по сбросу пароля
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form 
          ref="loginForm"
          @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Почта"
              placeholder="Почта"
              hide-details
              class="mb-4"
              :rules="[validators.required, validators.emailValidator]"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              type="submit"
            >
              Отправить
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Вернуться на страницу входа</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import themeConfig from '@themeConfig'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useRouter } from '@core/utils'

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Forgot Password'
  },
  setup() {
    const loginForm = ref(null)
    const isSnackbarCenteredVisible = ref(false)
    const snackbarMessage = ref(null)
    const auth = getAuth();
    auth.languageCode = 'ru';
    const { router } = useRouter()
    const email = ref()
    const errorMessages = ref([])
    const errorColor = ref('error')

    const handleFormSubmit = () => {
      if( loginForm.value.validate() ) {
        sendPasswordResetEmail(auth, email.value)
        .then(() => {
          // Password reset email sent!
          errorColor.value = 'success';
          snackbarMessage.value = 'Successfully Sent';
          isSnackbarCenteredVisible.value = true;

          loginForm.value.reset();
        })
        .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode)
        snackbarMessage.value = errorCode;
        isSnackbarCenteredVisible.value = true;

        // ..
      });
      }
    }

    return {
      handleFormSubmit,
      snackbarMessage,
      isSnackbarCenteredVisible,
      email,
      errorMessages,
      errorColor,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
      },
      validators: {
        required,
        emailValidator,
      },
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
